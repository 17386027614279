<template>
  <div class="data-manage">
    <div class="main-content" :class="showSearchDrawer ? 'active' : ''">
      <div class="page-title clearfix">
        <div class="float-left">
          <span>{{ $t("nav.data_manage") }}</span>
          <span class="total-num">{{ totalCount }}</span>
        </div>
        <div class="float-right">
          <button
            type="button"
            @click="goAddSubject"
            class="btn btn-danger btn-sm"
          >
            {{ $t("_data.add_data") }}
          </button>
          <!-- <button
            type="button"
            class="btn btn-primary btn-sm ml10"
            @click.stop="handleShowManagerPopover"
          >{{$t('_data.batch_edit')}}</button> -->
        </div>
      </div>
      <div class="data-content">
        <div class="search-wrapper">
          <span class="advanced-btn ml10 mr10" @click="showSearchWrapper">{{
            $t("a_search")
          }}</span>
          <button
            type="button"
            class="search-btn"
            @click.stop="handleCurrentSearch"
          >
            {{ $t("search") }}
          </button>
          <el-form :inline="true" class="search-form" size="small">
            <el-form-item label=" ">
              <el-input
                v-model="search"
                clearable
                :placeholder="$t('analyse.fuzzy_search')"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="search-selections clearfix">
            <span class="fl item mr5" v-if="searchFormData.search_number">
              <span class="item-name">{{ $t("_data.p_name") }}：</span>
              <span class="item-val">{{ searchFormData.search_number }}</span>
              <i
                class="el-icon-close"
                @click="clearSearchParam('search_number')"
              ></i>
            </span>
            <span class="fl item mr5" v-if="searchFormData.search_gender">
              <span class="item-name">{{ $t("gender") }}：</span>
              <span class="item-val">{{
                $t(getGenderKey(searchFormData.search_gender))
              }}</span>
              <i
                class="el-icon-close"
                @click="clearSearchParam('search_gender')"
              ></i>
            </span>
            <span
              class="fl item mr5"
              v-if="
                searchFormData.search_age_min && !searchFormData.search_age_max
              "
            >
              <span class="item-name">{{ $t("age") }}：</span>
              <span class="item-val"
                >{{ searchFormData.search_age_min }}-
              </span>
              <i
                class="el-icon-close"
                @click="clearSearchParam('search_age_min')"
              ></i>
            </span>
            <span
              class="fl item mr5"
              v-if="
                !searchFormData.search_age_min && searchFormData.search_age_max
              "
            >
              <span class="item-name">{{ $t("age") }}：</span>
              <span class="item-val">
                -{{ searchFormData.search_age_max }}</span
              >
              <i
                class="el-icon-close"
                @click="clearSearchParam('search_age_max')"
              ></i>
            </span>
            <span
              class="fl item mr5"
              v-if="
                searchFormData.search_age_min && searchFormData.search_age_max
              "
            >
              <span class="item-name">{{ $t("age") }}：</span>
              <span class="item-val"
                >{{ searchFormData.search_age_min }}-{{
                  searchFormData.search_age_max
                }}</span
              >
              <i
                class="el-icon-close"
                @click="clearSearchParam('clearAges')"
              ></i>
            </span>
            <span class="fl item mr5" v-if="create_date.length > 0">
              <span class="item-name">{{ $t("project.up_time") }}：</span>
              <span class="item-val"
                >{{ create_date[0] }}-{{ create_date[1] }}</span
              >
              <i
                class="el-icon-close"
                @click="clearSearchParam('create_date')"
              ></i>
            </span>
            <span class="fl item mr5" v-if="study_date.length > 0">
              <span class="item-name">{{ $t("project.s_date") }}：</span>
              <span class="item-val"
                >{{ study_date[0] }}-{{ study_date[1] }}</span
              >
              <i
                class="el-icon-close"
                @click="clearSearchParam('study_date')"
              ></i>
            </span>
            <span
              class="fl item mr5"
              v-if="searchFormData.search_modality.length > 0"
            >
              <span class="item-name">{{ $t("project.modality") }}：</span>
              <span
                class="item-val"
                v-for="item in searchFormData.search_modality"
                :key="item"
                >{{ item }}</span
              >
              <i
                class="el-icon-close"
                @click="clearSearchParam('search_modality')"
              ></i>
            </span>
            <span
              class="fl item mr5"
              v-if="searchFormData.search_series.length > 0"
            >
              <span class="item-name">{{ $t("_data.series_type") }}：</span>
              <span
                class="item-val"
                v-for="item in searchFormData.search_series"
                :key="item"
                >{{ item }}</span
              >
              <i
                class="el-icon-close"
                @click="clearSearchParam('search_series')"
              ></i>
            </span>
            <span
              class="fl item mr5"
              v-if="searchFormData.search_tag.length > 0"
            >
              <span class="item-name">{{ $t("_data.tags") }}：</span>
              <span
                class="item-val"
                v-for="item in searchFormData.search_tag"
                :key="item"
                >{{ item }}</span
              >
              <i
                class="el-icon-close"
                @click="clearSearchParam('search_tag')"
              ></i>
            </span>
            <span
              class="fl mr5 clear-search"
              v-if="showClearAllSearch"
              @click="resetForm('searchForm')"
              >{{ $t("_data.clear_filter") }}</span
            >
          </div>
        </div>
        <div class="data-process" v-if="processList.length > 0">
          <el-carousel
            height="20px"
            direction="vertical"
            arrow="never"
            indicator-position="none"
          >
            <el-carousel-item v-for="item in processList" :key="item.id">
              <div
                class="data-item clearfix"
                :class="processClass(item.status)"
              >
                <span class="fl mr5">{{ item.file_name }}</span>
                <span class="fl mr10">{{ item.status | formatStatus }}</span>
                <span v-if="item.status === '2'" class="fl mr10"
                  >{{ $t("package_status.failure_reason") }}：{{
                    item.error_status | formatMessage
                  }}</span
                >
                <span class="fl">{{ item.date_created }}</span>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="tabel-content">
          <el-table
            ref="multipleTable"
            :data="dataList"
            :empty-text="$t('no_data')"
            stripe
            style="width: 100%"
            header-row-class-name="thead-dark"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              :label="$t('_data.all')"
              width="60"
            ></el-table-column>
            <el-table-column
              prop="subject_name"
              :label="$t('_data.p_name')"
              width="180"
            >
              <template v-slot="scope">
                <router-link
                  :to="{
                    name: 'subjectDetail',
                    query: {
                      id: scope.row.id,
                      offset: currentPage,
                      from: 'datalist',
                    },
                  }"
                  >{{ scope.row.subject_name }}</router-link
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="subject_gender"
              :formatter="formatGender"
              :label="$t('gender')"
              width="70"
            ></el-table-column>
            <el-table-column prop="subject_age" :label="$t('age')" width="80">
              <template v-slot="scope">
                <span>
                  {{ scope.row.subject_age }}
                </span>
                <span v-if="scope.row.subject_age">
                  {{ $t($helper.formatAgeUnit(scope.row.subject_age_unit)) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="head[0].study_date"
              :label="$t('project.s_date')"
            >
            </el-table-column>
            <el-table-column
              prop="date_created"
              :label="$t('_data.upload_time')"
            ></el-table-column>
            <el-table-column
              prop="modality"
              :formatter="formatModality"
              :label="$t('project.modality')"
            ></el-table-column>
            <el-table-column :label="$t('_data.tags')">
              <template v-slot="scope">
                <span class="mr5" v-for="tag in scope.row.tags" :key="tag.id">{{
                  tag.name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="user.realname"
              :label="$t('_data.data_owner') + '(' + $t('sign.username') + ')'"
            >
              <template v-slot="scope">
                <span>
                  {{ scope.row.user.realname }} ({{ scope.row.user.username }})
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="user.department_name"
              :label="$t('_data.department')"
            ></el-table-column>
            <el-table-column :label="$t('operate')" align="right">
              <template v-slot="scope">
                <el-dropdown>
                  <span class="el-dropdown-link cursor">
                    <i class="fa fa-bars mr-1"></i>
                    <i class="fa fa-angle-down"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item disabled>{{
                      $t("operate_menu")
                    }}</el-dropdown-item>

                    <el-dropdown-item
                      divided
                      v-if="user.id === scope.row.user_id"
                      class="dropdown-item cursor"
                      @click.native="modifyInfo(scope.row)"
                    >
                      <i class="fa fa-edit"></i> {{ $t("_data.edit_p_info") }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      class="dropdown-item cursor"
                      @click.native="modalDownload(scope.row, 'nifti')"
                    >
                      <i class="fa fa-download"></i> {{ $t("_data.d_nif") }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      class="dropdown-item cursor"
                      @click.native="modalDownload(scope.row, 'dicom')"
                    >
                      <i class="fa fa-download"></i> {{ $t("_data.d_dic") }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="user.id === scope.row.user_id"
                      class="dropdown-divider"
                    ></el-dropdown-item>
                    <el-dropdown-item
                      v-if="user.id === scope.row.user_id"
                      class="dropdown-item cursor"
                      @click.native="openTagModel(scope.row)"
                    >
                      <i class="fa fa-tags"></i> {{ $t("_data.add_tag") }}
                    </el-dropdown-item>

                    <el-dropdown-item
                      divided
                      v-if="user.id === scope.row.user_id"
                      class="dropdown-item cursor text-danger"
                      @click.native="deleteSubject(scope.row)"
                    >
                      {{ $t("delete") }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- 

                <div class="dropdown d-inline-block dropleft">
                  <div class="cursor" @click.stop="showMenu(scope.row)">
                    <i class="fa fa-bars mr-1"></i>
                    <i class="fa fa-angle-down"></i>
                  </div>
                  <div
                    class="dropdown-menu shadow-lg"
                    :class="{ show: scope.row.id === rowId }"
                  >
                    <div class="dropdown-header">{{ $t("operate_menu") }}</div>
                    <div
                      v-if="user.id === scope.row.user_id"
                      class="dropdown-item cursor"
                      @click.stop="modifyInfo(scope.row)"
                    >
                      <i class="fa fa-edit"></i> {{ $t("_data.edit_p_info") }}
                    </div>
                    <div
                      class="dropdown-item cursor"
                      @click="modalDownload(scope.row, 'nifti')"
                    >
                      <i class="fa fa-download"></i> {{ $t("_data.d_nif") }}
                    </div>
                    <div
                      class="dropdown-item cursor"
                      @click="modalDownload(scope.row, 'dicom')"
                    >
                      <i class="fa fa-download"></i> {{ $t("_data.d_dic") }}
                    </div>
                    <div
                      v-if="user.id === scope.row.user_id"
                      class="dropdown-divider"
                    ></div>
                    <div
                      v-if="user.id === scope.row.user_id"
                      class="dropdown-item cursor"
                      @click="openTagModel(scope.row)"
                    >
                      <i class="fa fa-tags"></i> {{ $t("_data.add_tag") }}
                    </div>
                    <div class="dropdown-divider"></div>
                    <div
                      v-if="user.id === scope.row.user_id"
                      class="dropdown-item cursor text-danger"
                      @click="deleteSubject(scope.row)"
                    >
                      {{ $t("delete") }}
                    </div>
                  </div>
                </div> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="clearfix mt-3 mb-3">
        <div class="float-left">
          <pagination
            v-if="totalCount > 0"
            :pageSize="pageSize"
            :layout="layout"
            :currentPage="currentPage"
            :total="totalCount"
            @goToPage="handleCurrentChange"
          />
        </div>
        <div class="float-right">
          <!-- <button
            type="button"
            class="btn btn-danger btn-sm ml-2"
            @click.stop="batchRemoveData"
          >
            <i class="fa fa-trash-alt mr-1"></i>
            {{ $t("_data.batch_remove") }}
          </button> -->

          <button
            type="button"
            class="btn btn-info btn-sm ml-2"
            @click.stop="openDataOwnerPopover"
            v-if="user.role < 3"
          >
            <i class="fa fa-users mr-1"></i>
            {{ $t("_data.batch_edit") }}
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm ml-2"
            @click.stop="batchDownloadData"
          >
            <i class="fa fa-download mr-1"></i>
            {{ $t("_data.batch_download") }}
          </button>
        </div>
      </div>
    </div>
    <div class="empty-wrapper" :class="showSearchDrawer ? 'active' : ''"></div>
    <div class="search-drawer" :class="showSearchDrawer ? 'active' : ''">
      <p class="top-line clearfix">
        <span>{{ $t("a_search") }}</span>
        <span class="float-right close-search" @click="hideSearchWrapper">
          <i class="el-icon-close"></i>
        </span>
      </p>
      <div class="drawer-body">
        <div class="form-wrapper">
          <el-form
            :model="searchFormData"
            ref="searchForm"
            label-position="top"
            size="mini"
            class="search-form"
          >
            <el-form-item :label="$t('_data.p_name')" prop="search_number">
              <el-input
                v-model="searchFormData.search_number"
                @change="handleAdvanceSearch"
                @keydown.enter.stop="handleAdvanceSearch"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('gender')" prop="search_gender">
              <el-select
                v-model="searchFormData.search_gender"
                @change="handleAdvanceSearch"
                placeholder=" "
              >
                <el-option :label="$t('male')" value="M"></el-option>
                <el-option :label="$t('female')" value="F"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('age')" prop="age">
              <el-col :span="11">
                <el-form-item>
                  <el-input
                    v-model="searchFormData.search_age_min"
                    @change="handleAdvanceSearch"
                    @keydown.enter.stop="handleAdvanceSearch"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-form-item>
                  <el-input
                    v-model="searchFormData.search_age_max"
                    @change="handleAdvanceSearch"
                    @keydown.enter.stop="handleAdvanceSearch"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item :label="$t('project.s_date')">
              <el-date-picker
                v-model="study_date"
                value-format="yyyy-MM-dd"
                type="daterange"
                size="mini"
                :clearable="clearable"
                prefix-icon=" "
                range-separator="-"
                @change="handleAdvanceSearch"
              ></el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('project.up_time')">
              <el-date-picker
                v-model="create_date"
                value-format="yyyy-MM-dd"
                type="daterange"
                size="mini"
                :clearable="clearable"
                prefix-icon=" "
                range-separator="-"
                @change="handleAdvanceSearch"
              ></el-date-picker>
            </el-form-item>
            <div class="cutting-line"></div>
            <el-form-item :label="$t('project.modality')">
              <el-select
                v-model="searchFormData.search_modality"
                multiple
                @change="handleAdvanceSearch"
                placeholder=" "
              >
                <el-option
                  v-for="(item, index) in modalityOptions"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="cutting-line"></div>
            <el-form-item :label="$t('_data.series_type')">
              <el-select
                v-model="searchFormData.search_series"
                multiple
                @change="handleAdvanceSearch"
                placeholder=" "
              >
                <el-option
                  v-for="(item, index) in seriesOptions"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="cutting-line"></div>
            <div class="block">
              <span class="demonstration">{{ $t("_data.tags") }}</span>
              <el-select
                v-model="searchFormData.search_tag"
                multiple
                size="mini"
                @change="handleAdvanceSearch"
                placeholder=" "
              >
                <el-option
                  v-for="(item, index) in searchTagOptions"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </el-form>
        </div>
        <div class="button-wrapper">
          <button
            type="button"
            class="btn btn-primary btn-sm mr10"
            @click.stop="handleAdvanceSearch"
          >
            {{ $t("a_search") }}
          </button>
          <el-button size="small" @click="resetForm('searchForm')">{{
            $t("project.clear")
          }}</el-button>
        </div>
      </div>
    </div>
    <div class="brain-popover data-manager-popover" v-if="showOwnerPopover">
      <div class="popover-mask"></div>
      <div class="popover-content">
        <div class="popover-head">{{ $t("_data.batch_edit") }}</div>
        <div class="popover-body">
          <div class="row">
            <label class="col-sm-3 text-right" style="line-height: 32px">
              <span class="text-danger">* </span>{{ $t("_data.data_owner") }}:
            </label>
            <div class="col-sm-9">
              <el-cascader
                v-model="dataOwner"
                size="small"
                :props="{ checkStrictly: true }"
                :options="cascaderOptions"
                filterable
              >
              </el-cascader>
            </div>
          </div>
        </div>
        <div class="popover-footer text-right">
          <button
            type="button"
            class="btn btn-grey btn-sm ml10"
            @click.stop="closeDataOwnerPopover"
          >
            {{ $t("close") }}
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm ml10"
            @click.stop="setDataOwner"
          >
            {{ $t("confirm") }}
          </button>
        </div>
      </div>
    </div>
    <div class="dialog-wrapper" v-if="dialogVisible">
      <div class="dialog-content">
        <div class="dialog-header">
          <span class="dialog-title">{{ $t("_data.tips") }}</span>
          <button
            type="button"
            class="dialog-headerbtn"
            @click="dialogVisible = false"
          >
            <i class="dialog-close el-icon el-icon-close"></i>
          </button>
        </div>
        <div class="dialog-body">
          <span>{{ $t("_data.packing") }}</span>
        </div>
      </div>
    </div>

    <el-dialog
      :title="$t('notice.pack_ok')"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      :close-on-click-modal="isClickclose"
      :close-on-press-escape="isClickclose"
      :lock-scroll="isLockScroll"
    >
      <div class="text-center">
        <a
          class="btn btn-primary"
          target="_blank"
          :href="downloadurl"
          style="width: 120px"
          ><i class="el-icon-download"></i> {{ $t("_data.download") }}</a
        >
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>

    <edit-subject
      :patientInfo="edit_subject"
      :showPopover="showEditPopover"
      @closeEditPopover="showEditPopover = false"
      @submitSubject="submitSubject"
    >
    </edit-subject>
    <add-to-project
      :subject="edit_subject"
      :showPopover="showAddPopover"
      @closeAddPopover="showAddPopover = false"
      @addToProject="handleAddToProject"
    />
    <set-tags
      :tagsList="setTagOptions"
      :selectedTags="selectedTags"
      :showPopover="showTagsPopover"
      @closeTagsPopover="showTagsPopover = false"
      @addTag="addTag"
      @submitTags="submitTags"
    ></set-tags>
  </div>
</template>

<script>
import { eventHub } from "@/eventHub.js";
import editSubject from "../../components/subject/editSubject";
import addToProject from "../../components/subject/addToProject";
import setTags from "../../components/subject/setTags";
import {
  dataRequest,
  dataDownload,
  tagsRequest,
  modalityRequest,
  projectDataRequest,
  cascaderUserRequest,
  dataOwnerRequest,
  downloadPackage,
} from "../../api/api";
import pagination from "../../components/pager";
import { paginationMixin } from "../../utils/mixin";
import { MessageBox, Loading } from "element-ui";

let _that;
export default {
  mixins: [paginationMixin],
  components: {
    pagination,
    editSubject,
    addToProject,
    setTags,
  },
  data() {
    // console.log("router:::", this.$route.params);
    // console.log("store:::", this.$store.state.dataParams);
    return {
      dataList: [],
      processList: [],
      noTask: false,
      refresh: false,
      interval: null,
      rowId: "",
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
      search: this.$store.state.dataParams.search || "",
      searchFormData: {
        search_number: this.$store.state.dataParams.search_number || "",
        search_gender: this.$store.state.dataParams.search_gender || "",
        search_modality: this.$store.state.dataParams.search_modality || [],
        search_series: this.$store.state.dataParams.search_series || [],
        search_tag: this.$store.state.dataParams.search_tag || [],
        search_age_min: this.$store.state.dataParams.search_age_min || "",
        search_age_max: this.$store.state.dataParams.search_age_max || "",
      },
      modalityOptions: [],
      seriesOptions: [],
      searchTagOptions: [],
      create_date: [],
      study_date: [],
      setTagOptions: [],
      selectedTags: [],
      edit_subject: {},
      clearable: false,
      dialogVisible: false,
      showOwnerPopover: false,
      showSearchDrawer: false,
      showEditPopover: false,
      showAddPopover: false,
      showTagsPopover: false,
      addTagSubjectId: "",
      cascaderOptions: [],
      dataOwner: [],
      pageSize: 15,
      currentPage: parseInt(this.$route.query.offset) || 1,
      totalCount: 0,
      centerDialogVisible: false,
      isClickclose: false,
      isLockScroll: false,
      downloadurl: "",
    };
  },
  methods: {
    showSearchWrapper() {
      this.showSearchDrawer = true;
    },
    hideSearchWrapper() {
      this.showSearchDrawer = false;
    },
    handleAdvanceSearch() {
      //点击高级搜索按钮
      //console.log("handleAdvanceSearch");
      this.currentPage = 1;
      this.search = "";
      this.getDataList();
    },
    handleCurrentSearch() {
      //点击上方的搜索按钮
      //console.log("handleCurrentSearch");
      this.currentPage = 1;
      this.getDataList();
    },
    async getDataList() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "listallsubjects",
        userid: this.$store.state.userid,
        limit: this.pageSize,
        offset: this.currentPage - 1, //offset 0:第一页，1：第二页，...;
        search: this.search,
        ...this.searchFormData,
        search_createdate_min: this.create_date[0] || "",
        search_createdate_max: this.create_date[1] || "",
        search_studydate_min: this.study_date[0] || "",
        search_studydate_max: this.study_date[1] || "",
      };

      this.$store.state.dataParams = params;

      try {
        let res = await dataRequest(params);
        if (res.status === 200) {
          this.dataList = res.data.length > 0 ? res.data : [];
          this.totalCount = res.total;
        } else {
          this.dataList = [];
          this.totalCount = 0;
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    //获取级联用户列表
    async getCascaderOptions() {
      try {
        let res = await cascaderUserRequest();
        if (res.status === 200) {
          this.cascaderOptions = res.data ? res.data : [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    //获取高级搜索模态和序列
    async getModalityAndSeries() {
      try {
        let res = await modalityRequest();
        if (res.status === 200) {
          this.modalityOptions = res.data.data_modality
            ? res.data.data_modality
            : [];
          this.seriesOptions = res.data.data_series ? res.data.data_series : [];
          this.searchTagOptions = res.data.data_tags ? res.data.data_tags : [];
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDataList();
    },
    //获取顶部数据处理情况
    async getProcessList() {
      let params = {
        request: "upload_state",
        userid: this.$store.state.userid,
        projectid: "",
      };

      try {
        let res = await projectDataRequest(params);
        if (res.status === 200) {
          let resData = res.data;
          //this.refresh = this.processList.toString() !== resData.toString();
          //this.noTask = resData.length === 0;
          this.processList = resData.length > 0 ? resData : [];
        } else {
          this.processList = [];
          this.clearTimer();
        }
      } catch (error) {
        this.clearTimer();
        this.$helper.errNotify(error.message);
      }
    },
    processClass(status) {
      switch (status) {
        case "0":
          return "item-primary";
        case "1":
          return "item-success";
        case "2":
          return "item-danger";
        default:
          return "";
      }
    },
    resetForm(formName) {
      this.currentPage = 1;
      this.searchFormData.search_number = "";
      this.searchFormData.search_gender = "";
      this.searchFormData.search_modality = [];
      this.searchFormData.search_series = [];
      this.searchFormData.search_tag = [];
      this.searchFormData.search_age_min = "";
      this.searchFormData.search_age_max = "";
      this.study_date = [];
      this.create_date = [];
      this.getDataList();
    },
    openDataOwnerPopover() {
      if (!this.multipleSelection || this.multipleSelection.length === 0) {
        this.$helper.warningMessage(this.$t("notice.select_data"));
        return;
      }
      this.showOwnerPopover = true;
    },
    closeDataOwnerPopover() {
      this.showOwnerPopover = false;
      this.dataOwner = [];
    },
    async setDataOwner() {
      if (this.dataOwner.length === 0) {
        this.$helper.warningMessage(this.$t("notice.select_data_owner"));
        return;
      }

      let subjects = this.multipleSelection.map(function (item) {
        return item.id;
      });

      const _index = this.dataOwner.length - 1;
      let params = {
        subject_ids: subjects,
        user_id: this.dataOwner[_index],
      };

      try {
        let res = await dataOwnerRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getDataList();
          this.showOwnerPopover = false;
          this.dataOwner = [];
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    handleSelectionChange(value) {
      this.multipleSelection = value;
    },
    goAddSubject() {
      this.$router.push("/data-manage/add-subject");
    },
    formatGender: function (row) {
      return row.subject_gender === "1"
        ? this.$t("female")
        : row.subject_gender === "0"
        ? this.$t("male")
        : "";
    },
    formatModality: function (row) {
      let modalityStr = "";
      if (row.modality && row.modality.length > 0) {
        modalityStr = row.modality.reduce((x, y) => {
          return x + " " + y;
        });
      }
      return modalityStr;
    },
    showMenu(rowData) {
      this.rowId = rowData.id;
    },
    hideMenu() {
      this.rowId = "";
    },
    async modalDownload(subject, type) {
      const me = this;
      //this.dialogVisible = true;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.rowId = "";
      const { id } = this.user;
      let params = {
        userid: id,
        subjectid: subject.id,
        projectid: subject.project_id || 0,
        request: "download_" + type,
      };
      try {
        let res = await downloadPackage(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          eventHub.$emit("getProcessList");

          //window.open(res.downloadurl);
          //换弹窗，链接打开下载url;
          // me.downloadurl = res.downloadurl;
          // me.centerDialogVisible = true;
        } else {
          this.$helper.errNotify(res.message);
        }
        //this.dialogVisible = false;

        loadingInstance.close();
      } catch (error) {
        //this.dialogVisible = false;
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    //批量下载
    async batchDownloadData() {
      const me = this;
      if (!this.multipleSelection || this.multipleSelection.length === 0) {
        this.$helper.warningMessage(this.$t("notice.select_tip"));
        return;
      }
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { id } = this.user;
      let subjects = this.multipleSelection.map(function (item) {
        return item.id;
      });
      let params = {
        userid: id,
        subjects: subjects,
        projectid: 0,
        request: "batch_download_subject_list",
      };
      try {
        let res = await downloadPackage(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          //location.href = res.downloadurl;
          eventHub.$emit("getProcessList");

          //换弹窗，链接打开下载url;
          // me.downloadurl = res.downloadurl;
          // me.centerDialogVisible = true;
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    deleteSubject(subject) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteSubjectFunc(subject);
        })
        .catch(() => {});
    },
    async deleteSubjectFunc(subject) {
      this.rowId = "";
      let params = {
        request: "remove",
        userid: this.user.id,
        subject_id: subject.id,
        projectid: subject.project_id || 0,
        groupid: subject.group_id || 0,
      };
      try {
        let res = await dataRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getDataList();
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    batchRemoveData() {
      // const me = this;
      // if (!this.multipleSelection || this.multipleSelection.length === 0) {
      //   this.$helper.warningMessage(this.$t("notice.select_tip"));
      //   return;
      // }
      // this.dialogVisible = true;
      // const { id } = this.user;
      // let subjects = this.multipleSelection.map(function (item) {
      //   return item.id;
      // });
      // MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
      //   confirmButtonText: this.$t("confirm"),
      //   cancelButtonText: this.$t("cancel"),
      //   type: "warning",
      // })
      //   .then(() => {
      //     this.deleteSubjectFunc(subject);
      //   })
      //   .catch(() => {});
    },
    modifyInfo(subject) {
      this.edit_subject = {
        subject_id: subject.id,
        subject_name: subject.subject_name,
        subject_age: subject.subject_age,
        subject_age_unit: subject.subject_age_unit,
        subject_gender: subject.subject_gender,
        subject_explain: subject.subject_explain,
        project_id: subject.project_id,
      };
      this.showEditPopover = true;
      this.rowId = "";
    },
    async submitSubject(subject) {
      let _self = this;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "edit",
        userid: _self.$store.state.userid,
        ...subject,
      };
      try {
        let res = await dataRequest(params);
        if (res.status === 200) {
          _self.$helper.successNotify(res.message);
          _self.getDataList();
          _self.showEditPopover = false;
        } else {
          _self.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        _self.$helper.errNotify(error.message);
        loadingInstance.close();
      }
    },
    async getTagOptions() {
      let params = {
        request: "list",
        userid: this.$store.state.userid,
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.setTagOptions = res.data.length > 0 ? res.data : [];
        }
      } catch (error) {}
    },
    openTagModel(rowData) {
      this.selectedTags = rowData.tags.map(function (item) {
        return item.id;
      });
      this.addTagSubjectId = rowData.id;
      this.showTagsPopover = true;
    },
    async addTag(tagName) {
      if (!tagName) {
        this.$helper.warningMessage(this.$t("notice.tag_name_required"));
        return false;
      }

      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "insert",
        userid: this.$store.state.userid,
        name: tagName,
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getTagOptions();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async submitTags(tags) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        request: "tagsubject",
        userid: this.$store.state.userid,
        subject_id: this.addTagSubjectId,
        tagids: tags,
        tagname: "",
      };
      try {
        let res = await tagsRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getDataList();
          this.showTagsPopover = false;
          this.addTagSubjectId = "";
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        this.$helper.errNotify(error.message);
        loadingInstance.close();
      }
    },
    clearSearchParam(searchParam) {
      if (
        [
          "search_number",
          "search_gender",
          "search_age_min",
          "search_age_max",
        ].indexOf(searchParam) > -1
      ) {
        this.searchFormData[searchParam] = "";
      } else if (
        ["search_modality", "search_series", "search_tag"].indexOf(
          searchParam
        ) > -1
      ) {
        this.searchFormData[searchParam] = [];
      } else if (["create_date", "study_date"].indexOf(searchParam) > -1) {
        this[searchParam] = [];
      } else if (searchParam === "clearAges") {
        this.searchFormData.search_age_min = "";
        this.searchFormData.search_age_max = "";
      }
      this.getDataList();
    },
    handleAddToProject() {},
    getGenderKey(gender) {
      return gender === "M" ? "male" : gender === "F" ? "female" : "";
    },
    pollData() {
      this.interval = setInterval(() => {
        this.getProcessList();
      }, 10000);
    },
    clearTimer() {
      clearInterval(this.interval);
      this.interval = null;
    },
  },
  computed: {
    showClearAllSearch: function () {
      const _self = this;
      const existSearchParam = [
        "search_number",
        "search_gender",
        "search_age_min",
        "search_age_max",
      ].some(function (item) {
        return !!_self.searchFormData[item];
      });
      const existSearchArr = [
        "search_modality",
        "search_series",
        "search_tag",
      ].some(function (item) {
        return _self.searchFormData[item].length > 0;
      });
      const existDateArr = ["create_date", "study_date"].some(function (item) {
        return _self[item].length > 0;
      });
      if (existSearchParam || existSearchArr || existDateArr) {
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    if (
      this.$store.state.dataParams.search_createdate_min != "" &&
      this.$store.state.dataParams.search_createdate_max != ""
    ) {
      this.create_date = [
        this.$store.state.dataParams.search_createdate_min,
        this.$store.state.dataParams.search_createdate_max,
      ];
    }

    if (
      this.$store.state.dataParams.search_studydate_min != "" &&
      this.$store.state.dataParams.search_studydate_max != ""
    ) {
      this.study_date = [
        this.$store.state.dataParams.search_studydate_min,
        this.$store.state.dataParams.search_studydate_max,
      ];
    }
    this.getDataList();
    this.pollData();
  },
  mounted() {
    this.getProcessList();
    this.getCascaderOptions();
    this.getModalityAndSeries();
    this.getTagOptions();
    document.body.addEventListener("click", this.hideMenu, false);
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.hideMenu);
    this.clearTimer();
  },
  watch: {
    // noTask(newVal, oldVal) {
    //   console.log(newVal, oldVal);
    //   if (newVal) {
    //     clearInterval(this.interval);
    //     this.interval = null;
    //   }
    // },
    // refresh(newVal, oldVal) {
    //   if (newVal) {
    //     this.getDataList();
    //   }
    // },
    processList(newVal, oldVal) {
      if (newVal.length != oldVal.length) {
        this.getDataList();
      }
    },
  },
  beforeCreate() {
    _that = this;
  },
  filters: {
    formatSearchGender: function (val) {
      return val === "M" ? "男" : val === "F" ? "女" : "";
    },
    formatSearchTag: function (val, tagOptions) {
      var tag = tagOptions.filter(function (item) {
        return item.id == val;
      });
      return tag[0].name || "";
    },
    formatStatus: function (status) {
      switch (status) {
        case "0":
          return _that.$t("package_status.processing");
        case "1":
          return _that.$t("package_status.completed");
        case "2":
          return _that.$t("package_status.failed");
        default:
          return "";
      }
    },
    formatMessage: function (status) {
      switch (status) {
        case "1":
          return _that.$t("package_status.failed_niix");
        case "2":
          return _that.$t("package_status.no_dicom");
        default:
          return _that.$t("package_status.failed");
      }
    },
  },
};
</script>

<style lang="less">
.table {
  border-bottom: 1px solid #dee2e6;
}

.data-manage {
  .data-process {
    margin-bottom: 10px;

    .data-item {
      // margin-bottom: 10px;
      padding: 0 5px 0 10px;
      height: 20px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &.item-primary {
        background: #e4eef9;
        color: #027bfe;
      }

      &.item-success {
        background: #e6f1ec;
        color: #26a745;
      }

      &.item-danger {
        background: #f4e8ec;
        color: #dc3545;
      }

      .close-btn {
        width: 20px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }

  .main-content {
    transition: 0.3s ease-in;
  }

  .main-content.active {
    width: calc(~"100% - 240px");
    // transform: translate(-220px);
    // margin-right: 220px;
  }

  .el-checkbox {
    margin-bottom: 0;
    // color: #fff;
  }

  .el-table .cell {
    overflow: visible;
    //word-break: keep-all;
  }

  .el-table {
    overflow: visible;
  }

  .el-table__body-wrapper {
    overflow: visible !important;
  }
}

.data-manager-popover {
  .popover-content {
    margin: 120px auto 0;
    width: 700px;
    background: #fff;
  }

  .el-cascader {
    width: 100%;
  }
}

.dialog-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2003;
  overflow: auto;
  margin: 0;
  background: rgba(0, 0, 0, 0.6);

  .dialog-content {
    position: relative;
    margin: 15vh auto;
    border-radius: 3px;
    width: 600px;
    background: #fff;
  }

  .dialog-header {
    padding: 20px 10px;
    border-bottom: 1px solid #dee2e6;
  }

  .dialog-title {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
  }

  .dialog-headerbtn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }

  .dialog-body {
    padding: 30px 0;
    text-align: center;
  }
}

.search-drawer {
  padding-bottom: 30px;
  overflow-y: scroll;

  .el-form-item--mini.el-form-item {
    margin-bottom: 10px;
  }

  .el-form-item--mini .el-form-item__label {
    line-height: 20px;
  }

  .el-form--label-top .el-form-item__label {
    padding-bottom: 0;
  }

  .line {
    text-align: center;
  }

  .el-col {
    .el-form-item--mini.el-form-item {
      margin-bottom: 0;
    }
  }

  .top-line {
    margin-bottom: 10px;
  }

  .el-select {
    width: 100%;
  }

  .cutting-line {
    margin-bottom: 10px;
    border-top: 1px solid #dddddd;
  }

  .button-wrapper {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #dddddd;
    text-align: center;
  }
}
</style>
