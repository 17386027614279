<template>
  <div class="brain-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title">{{ $t("_data.add_to_pro") }}</span>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click.stop="closePopover"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="popover-body project-popover-body">
        <el-form :model="subject" :rules="rules" ref="subjectForm" label-width="150px">
          <el-form-item :label="$t('_data.project') + '：'" prop="project">
            <el-select v-model="subject.project">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('project.group') + '：'" prop="group">
            <el-select v-model="subject.group">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- <div class="form-group row">
            <label for="subject_age" class="col-sm-3 col-form-label text-right">
              <span class="text-danger">*</span>
              {{ $t("project.group") }}:
            </label>
            <div class="col-sm-9">
              <el-select v-model="value" clearable placeholder="">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div> -->
        </el-form>
      </div>
      <div class="popover-footer text-right">
        <button
          type="button"
          class="btn btn-grey btn-sm ml10"
          @click.stop="closePopover"
        >
          {{ $t("cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm ml10"
          @click.stop="submitSubject('subjectForm')"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subject: {
      type: Object,
      default: function() {
        return {};
      }
    },
    showPopover: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [
        // {
        //   value: "选项1",
        //   label: "黄金糕"
        // },
        // {
        //   value: "选项2",
        //   label: "双皮奶"
        // },
        // {
        //   value: "选项3",
        //   label: "蚵仔煎"
        // },
        // {
        //   value: "选项4",
        //   label: "龙须面"
        // },
        // {
        //   value: "选项5",
        //   label: "北京烤鸭"
        // }
      ],
      value: "",
    };
  },
  computed: {
    rules() {
      return {
        project: [
          { required: true, message: " ", trigger: 'change' }
        ],
        group: [
          { required: true, message: " ", trigger: 'change' }
        ],
      }
    }
  },
  methods: {
    submitSubject(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("addToProject", this.subject);
        } else {
          return false;
        }
      });
    },
    closePopover() {
      this.$emit("closeAddPopover");
    }
  }
};
</script>

<style lang="less">
.brain-popover .project-popover-body {
  padding-right: 100px;
  .el-select {
    width: 100%;
  }
}
</style>
